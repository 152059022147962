export type ElementComparer = (el1: HTMLElement, el2: HTMLElement) => boolean;

interface JQuerish {
	[index: number]: HTMLElement
	length: number
}

export const getMatrix = function(oldList: JQuerish, newList: JQuerish, isSame: ElementComparer): number[][] {

	let asc: boolean;
	let end: number;
	let k: number;
	let i: number;
	let j: number;
	let asc1: boolean;
	let end1: number;
	let i1: number;
	let asc2: boolean;
	let end2: number;
	const matrix: number[][] = [ [] ];

	for (k = 0, i = k, end = oldList.length+1, asc = 0 <= end; asc ? k < end : k > end; asc ? k++ : k--, i = k) {
		matrix[i] = [ i ];
		i++;
	}

	for (i1 = 0, j = i1, end1 = newList.length+1, asc1 = 0 <= end1; asc1 ? i1 < end1 : i1 > end1; asc1 ? i1++ : i1--, j = i1) {
		matrix[0][j] = j;
		j++;
	}

	for (i = 1, end2 = oldList.length+1, asc2 = 1 <= end2; asc2 ? i < end2 : i > end2; asc2 ? i++ : i--) {
		let asc3, end3;
		for (j = 1, end3 = newList.length+1, asc3 = 1 <= end3; asc3 ? j < end3 : j > end3; asc3 ? j++ : j--) {
			if (isSame(oldList[i - 1], newList[j - 1])) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + 1);
			}
		}
	}
	return matrix;
};

type NOOP = {
	type: 'noop'
}

type INSERT = {
	type: 'insert',
	element: HTMLElement
};

type REMOVE = {
	type: 'remove',
	index?: number
};
export type Operation = NOOP | INSERT | REMOVE

export const getScript = function(matrix: number[][], newList: JQuerish): Operation[] {
	let i = matrix.length - 1;
	let j = matrix[i].length - 1;
	const script: Operation[] = [];
	let current: number;
	let insert: number;
	let remove: number;
	let substitute: number;

	while ((i > 0) || (j > 0)) {

		current = matrix[i][j];

		if (current === 0) {
			i--;
			j--;
			script.unshift({
				type: 'noop'});
			continue;
		}

		if (i === 0) {
			j--;
			script.unshift({
				type: 'insert',
				element: newList[j]});
			continue;
		}

		if (j === 0) {
			i--;
			script.unshift({
				type: 'remove',
				index: i
			});
			continue;
		}

		substitute = matrix[i - 1][j - 1];
		remove = matrix[i - 1][j];
		insert = matrix[i][j - 1];

		if ((current === substitute) && (substitute <= insert) && (substitute <= remove)) {
			i--;
			j--;
			script.unshift({
				type: 'noop'});
			continue;
		}

		if ((insert <= remove) && (insert <= substitute)) {
			j--;
			script.unshift({
				type: 'insert',
				element: newList[j]});
			continue;

		} else if ((remove <= insert) && (remove <= substitute)) {
			i--;
			script.unshift({
				type: 'remove'});
			continue;
		}

		// Substitute = Remove + Insert
		i--;
		j--;
		script.unshift({
			type: 'remove'});

		script.unshift({
			type: 'insert',
			element: newList[j]});
	}
	return script;
};
