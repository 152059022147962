import $ from 'jquery';
import {
	ElementComparer,
	getMatrix,
	getScript,
	Operation
} from '@/levenshtein';

// Takes two lists of elements assumed to be of same length,
// compares the elements pairwise, and uses the state of the elements
// in the second list to update the state of the elements in the first.
export const updateElements = function(oldElements: JQuery, newElements: JQuery): void {
	console.groupCollapsed('Update elements')
	if (oldElements.length !== newElements.length) {
		console.warn("Not of same length");
	}

	for (let i = 0; i < oldElements.length; i++)
	{
		const oldElement = oldElements[i];
		const newElement = newElements[i];
		updateElement(oldElement, newElement);
	}
	console.groupEnd()
};

const updateElement = function(oldElement: HTMLElement, newElement: HTMLElement): void {

	const updateSelector = '[data-update-key]';
	const $o = $(oldElement).find(updateSelector).addBack(updateSelector);

	if (!($o.length > 0)) { return; }

	for (let i = 0; i < $o.length; i++) {
		const key = $o[i].dataset.updateKey;
		const q = `[data-update-key=${ key }]`;
		updateKey($o[i], $(newElement).find(q).addBack(q)[0]);
	}

};

function updateAttr(data: DOMStringMap, newElement: HTMLElement, oldElement: HTMLElement) {
	const updateAttr = data.updateAttr;

	if (updateAttr == null) {
		return;
	}

	const newValue = newElement.getAttribute(updateAttr);
	const oldValue = oldElement.getAttribute(updateAttr);
	if (oldValue === newValue) {
		return;
	}

	if (newValue == null) {
		console.debug(`removing attribute ${ updateAttr }`);
		oldElement.removeAttribute(updateAttr);
	} else {
		console.debug(`updating attribute ${updateAttr}`, oldValue, newValue);
		oldElement.setAttribute(updateAttr, newValue);
	}
}

const updateKey = function(oldElement: HTMLElement, newElement: HTMLElement | null): void {

	if (oldElement.parentNode == null) return;

	if (newElement == null) {
		console.debug("Removing", oldElement);
		oldElement.parentNode.removeChild(oldElement);
		return;
	}

	const data = oldElement.dataset;

	if ((data.updateHtml != null) && (oldElement.outerHTML !== newElement.outerHTML)) {
		console.debug("Updating HTML");
		oldElement.outerHTML = newElement.outerHTML;
		return;
	}
	updateAttr(data, newElement, oldElement);
};

const stylesToSnapshot: [
	'transform',
	'webkitTransform'
] = [
	'transform',
	'webkitTransform'
];

declare global {
	interface JQuery {
		snapshotStyles(): JQuery;
		releaseSnapshot(): JQuery;
		fadeOutAndRemove(): JQuery;
		updateList(html: string, options: UpdateListOptions): JQuery;
	}
}

// A lot of the inspiration for this, including the implementation of
// the following two jQuery plugins, comes from a blog post by Steven
// Sanderson (of Knockout.js fame) titled "Animating lists with CSS 3
// transitions", which can be found here:
// http://blog.stevensanderson.com/2013/03/15/animating-lists-with-css-3-transitions/
$.fn.snapshotStyles = function(): JQuery {
	$(this).each(function() {
		for (let i = 0; i < stylesToSnapshot.length; i++) {
			const name = stylesToSnapshot[i];
			this.style[name] = getComputedStyle(this)[name];
		}
		$(this).addClass('in-transit');
	});
	return this;
};

$.fn.releaseSnapshot = function(): JQuery {
	return $(this).each(function() {
		this.offsetHeight;
		// Force position to be recomputed before transition starts
		for (let i = 0; i < stylesToSnapshot.length; i++) {
			this.style[stylesToSnapshot[i]] = '';
		}

		$(this).removeClass('in-transit');
	});
};

$.fn.fadeOutAndRemove = function(): JQuery {
	return $(this)
		.removeClass('visible')
		.fadeOut(1000, function() { return $(this).remove(); });
};

type UpdateListOptions = { visibleSelector?: string, compare?: ElementComparer };
$.fn.updateList = function(html: string, options: UpdateListOptions = {}): JQuery {

	console.groupCollapsed("Update list");
	const requiredOptions: Required<UpdateListOptions> = {
		compare(a, b) { return a.id === b.id; },
		visibleSelector: '.visible',
		...options
	}

	const $newItems = $(html).filter(function() { return this.nodeType === Node.ELEMENT_NODE; });

	$(this).each(function(): void {
		const $oldItems = $(this).find(requiredOptions.visibleSelector);
		const matrix = getMatrix($oldItems, $newItems, requiredOptions.compare);
		const script = getScript(matrix, $newItems);

		console.debug("Update script: ", script.map(op => op.type));

		applyScript(script, $(this), requiredOptions.compare);
		updateElements($(this).find(requiredOptions.visibleSelector), $newItems);
	});

	console.groupEnd();
	return this;
};

const applyScript = function(script: Operation[], $container: JQuery, isSame: ElementComparer) {

	let el: HTMLElement | undefined;
	const removedElements: HTMLElement[] = [];
	const movedElements: HTMLElement[] = [];

	let i = 0;

	for (const command of script) {
		el = undefined;

		switch (command.type) {
			case 'noop':
				i++;
				break;

			case 'remove':
				el = $container.find(".visible")[i];

				// Save element in case it is inserted later:
				removedElements.push(el);

				console.debug('Marking element at ', i, ' for removal.');
				i++;
				break;

			case 'insert': {
				const j = removedElements.findIndex((candidate) => isSame(command.element, candidate));

				if (j > -1) {
					// el was previously removed

					el = removedElements[j];
					removedElements.splice(j, 1);

						// Important: Style snapshot must be made *before* the element is detached from the DOM
						$(el).snapshotStyles().detach();
					i--;
					// This is an element that was previously removed.
					console.debug('Inserting previously removed element at ', i);
					movedElements.push(el);
				} else {
					// el is a new element
					console.debug('Inserting new element at ', i);
					el = command.element;
				}

				if (i > 0) {
					$($container.find(".visible")[i - 1]).after(el);
				} else {
					$container.prepend(el);
				}
				i++;
				break;
			}
		}
	}

	for (el of movedElements) {
		console.debug("Releasing snapshot", el);
		$(el).releaseSnapshot();
	}

	for (el of removedElements) {
		console.debug("Removing", el);
		$(el).fadeOutAndRemove();
	}
};
