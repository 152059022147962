import BRJQuery from 'jquery'
import { ajax, getScript, getJSON } from 'jquery'
import '@/jquery.list-animation';

// Set up jQuery
const csrfToken = BRJQuery('meta[name="csrf-token"]').attr('content') || '';
BRJQuery.ajaxSetup({
	// We trust that cache headers are set correctly
	cache: true,

	beforeSend(xhr) {
		return xhr.setRequestHeader('X-CSRF-Token', csrfToken);
	}
});

Object.assign(window, { jQuery: BRJQuery });

export { ajax, getScript, getJSON };

export default BRJQuery;
